import _get from './get';
import _post from './post';
// import _put from './put';

const UserMediaMetadataManager = {
	_get,
	_post
};

export default UserMediaMetadataManager;
