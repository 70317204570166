import moment from "moment";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { ContentType } from "../AppConstants/TypeConstants";
// import { scroller } from "react-scroll";
import axios from "axios";
// import { isMobileDevice } from "./getPlatformInfo";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { ArrayJoin } from "../Clevertap/EventSchema.ts";
gsap.registerPlugin(ScrollToPlugin);

export const showToast = (message, attributes = {}) => {
  let obj = {
    position: attributes.position || "bottom-right",
    autoClose: attributes.autoClose || 5000,
    hideProgressBar: attributes.hideProgressBar || true,
    closeOnClick: attributes.closeOnClick || true,
    pauseOnHover: attributes.pauseOnHover || false,
    draggable: attributes.draggable || true,
    progress: attributes.progress || undefined,
    className: "toast-container",
    type: attributes.type || "", //error,warn,success,info
  };

  toast(message, obj);
};

// check for valid email
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const validateName = (name, value) => {
  // Minimum and maximum length validation
  const minLength = 2;
  const maxLength = 50;
  if (value.length < minLength || value.length > maxLength) {
    return (
      `${name} name must be between` +
      minLength +
      " and " +
      maxLength +
      " characters long."
    );
  }

  // Character validation
  if (!/^[a-zA-Z]+$/.test(value)) {
    return `${name} name can only contain alphabetic characters.`;
  }

  // Whitespace trimming
  value = value.trim();

  // Capitalization
  value = value.charAt(0).toUpperCase() + value.slice(1);

  // No numbers or symbols validation
  if (/[\d!@#$%^&*()_+={}\[\]:;"'|\\<,>.?/~`]/.test(value)) {
    return `${name} name cannot contain numbers or symbols.`;
  }

  // Error message for empty field
  if (value.length === 0) {
    return `${name} name is required.`;
  }

  // Success
  return "";
};

export const getActivePage = () => {
  return window.location.pathname;
};

export const isProd = process.env.NODE_ENV === "production";

export const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};
export const secondsToHms = (seconds) => {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
  seconds = Number(seconds);
  var h = Math.floor(seconds / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor((seconds % 3600) % 60);

  // improved answer to omit the unwanted 0
  const parts = [("0" + s).slice(-2)];
  if (m || (!m && h)) parts.unshift(("0" + m).slice(-2));
  if (h) parts.unshift(("0" + h).slice(-2));
  return parts.join(":");

  // var hDisplay = h > 0 ? h + (h === 1 ? " : " : " : ") : "";
  // var mDisplay = m > 0 ? m + (m === 1 ? " : " : " : ") : "0 : ";
  // var sDisplay = s > 0 ? s + (s === 1 ? " " : " ") : "00";
  // return hDisplay + mDisplay + sDisplay;
};

export const getTimeFormat = (SECONDS) => {
  return new Date(SECONDS * 1000).toISOString().slice(11, 19);
};
export const getTime = (timeValue, interval) => {
  const m1 = moment();
  const m2 = moment(timeValue);
  const seconds = m2.diff(m1, "seconds");

  let timeLeft = {};
  if (seconds > 0) {
    timeLeft = {
      d: Math.floor(seconds / (3600 * 24)),
      h: Math.floor((seconds % (3600 * 24)) / 3600),
      m: Math.floor((seconds % 3600) / 60),
      s: Math.floor(seconds % 60),
    };
  } else {
    clearInterval(interval);
  }
  return { ...timeLeft, seconds };
};

export const updateURLQuery = (history, location, paramName, paramValue) => {
  let urlQuery = new URLSearchParams(location.search);
  urlQuery.set(paramName, paramValue);
  history.replace({
    search: urlQuery.toString(),
  });
};

export const removeURLQuery = (history, location, paramName) => {
  let urlQuery = new URLSearchParams(location.search);
  urlQuery.delete(paramName);
  history.replace({
    search: urlQuery.toString(),
  });
};

export const getURLParam = (newUrlParam = [], location = null) => {
  let urlQuery = new URLSearchParams(location ? location.search : "");
  if (newUrlParam.length > 0) {
    newUrlParam.forEach((param) => {
      urlQuery.set(param.name, param.value);
    });
  }
  return urlQuery.toString();
};

export const updateSearchParamsInURL = (search, propertyName, value) => {
  let params = new URLSearchParams(search);
  params.set(propertyName, value);
  return `?${params.toString()}`;
};

export const updateSearchParamsForSeason = (
  search,
  EP_OR_TRAILER,
  seasonIndex,
  episodeIndex
) => {
  let urlQuery = new URLSearchParams(search);
  if (EP_OR_TRAILER === "ep") {
    urlQuery.has(ContentType.TRAILER) && urlQuery.delete(ContentType.TRAILER);
  } else {
    urlQuery.has("ep") && urlQuery.delete("ep");
  }

  let updatedSeasonParams = updateSearchParamsInURL(
    urlQuery,
    "season",
    seasonIndex
  );
  let updatedSearch = updateSearchParamsInURL(
    updatedSeasonParams,
    EP_OR_TRAILER,
    episodeIndex
  );

  return updatedSearch;
};

export const redirectClinet = (
  history,
  location,
  newPath,
  newUrlParam = [],
  removeUrlParam = [],
  hash = null
) => {
  let urlQuery = new URLSearchParams(location.search);
  if (newUrlParam.length > 0) {
    newUrlParam.forEach((param) => {
      urlQuery.set(param.name, param.value);
    });
  }
  if (removeUrlParam.length > 0) {
    removeUrlParam.forEach((param) => {
      urlQuery.delete(param);
    });
  }
  let locationProp = {
    pathname: newPath,
    search: urlQuery.toString(),
  };
  if (hash) {
    locationProp = {
      ...locationProp,
      hash,
    };
  }
  history.push(locationProp);
};

export function checkObjectNull(obj) {
  for (var key in obj) {
    if (obj[key] !== null && obj[key] != "") return false;
  }
  return true;
}

/* Get the documentElement (<html>) to display the page in fullscreen */
var elem = document.documentElement;

/* View in fullscreen */
export const openFullscreen = () => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
};
/* Close fullscreen */
export const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
};

// scroll into view with offset
export const scrollToTargetAdjusted = (id, offsetY) => {
  var element = document.getElementById(id);
  var headerOffset = offsetY;
  var elementPosition = element.getBoundingClientRect().top;
  var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const scrollToBottom = (id) => {
  var objDiv = document.getElementById(id);
  objDiv.scrollTop = objDiv.scrollHeight;
};

// trim string at certain length
export const trimString = (string, limit, needDotes = true) => {
  if (string.length <= limit) {
    return string;
  }
  return needDotes ? string.slice(0, limit) + "..." : string.slice(0, limit);
};

//load a image
export const loadImageAsync = (imgURL) => {
  return new Promise(async (res, rej) => {
    try {
      let img = new Image();
      img.onload = () => res();
      img.onerror = (err) => rej(err);
      img.src = imgURL;
    } catch (error) {
      rej(error);
    }
  });
};
export const formatSpeakerName = (array) => {
  if (!array || !array.length) {
    return "";
  }
  let s = [array.slice(0, -1).join(", "), array.slice(-1)[0]].join(
    array.length < 2 ? "" : " & "
  );
  return s;
};

export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

const searchRegExp = /[^A-Z0-9]/gi;
export const replacePointsWithDash = (String) => {
  return String.trim().replace(searchRegExp, "_");
};

export const windowOpenUrl = (url) => {
  window.open(url);
};

export const exportFile = (data, sheetName, filename) => {
  const wb = XLSX.utils.book_new();
  const wsAll = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, wsAll, sheetName);
  XLSX.writeFile(wb, filename);
};

export const getDynamicLink = async ({
  link,
  uid = "",
  title = "",
  description = "",
  id = "",
  thumbnailUrl = "",
  utmMedium = "dynamic_link",
  type = "",
}) => {
  let fallBackURL = `${link}${type === "event" || type === ContentType.CHAPTER || type === "ott"
    ? "?"
    : "&"
    }utm_medium=${utmMedium}&utm_source=${uid}&utm_content=${encodeURIComponent(
      id
    )}&utm_campaign=direct`;

  try {
    let response = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_API_KEY}`,
      {
        dynamicLinkInfo: {
          // domainUriPrefix is created in your Firebase console
          domainUriPrefix: "https://share.docflix.com",
          link: fallBackURL,
          analyticsInfo: {
            googlePlayAnalytics: {
              utmSource: uid,
              utmMedium: utmMedium,
              utmCampaign: "direct",
              // utmTerm: string,
              utmContent: id,
            },
            // itunesConnectAnalytics: {
            //   at: string,
            //   ct: string,
            //   mt: string,
            //   pt: string,
            // },
          },
          socialMetaTagInfo: {
            socialTitle: title,
            socialDescription: description,
            socialImageLink: thumbnailUrl,
          },
          androidInfo: {
            androidPackageName: "com.mankind.docflix",
            androidFallbackLink: fallBackURL,
            // androidMinPackageVersionCode: "1.0",
          },
          iosInfo: {
            iosBundleId: "com.mankind.docflix",
            iosAppStoreId: "1631729377",
            iosFallbackLink: fallBackURL,
          },
        },
      }
    );
    if (response.status === 200) {
      return response.data.shortLink;
    }
    return fallBackURL;
  } catch (error) {
    console.error(error);
    return link;
  }
};

export const modifyPropertyInArray = async (
  arr,
  targetType,
  oldValue,
  targetId,
  newProperty
) => {
  const targetObj = arr.find((obj) => obj[oldValue] === targetId);
  if (targetObj) {
    targetObj[targetType] = newProperty;
  }
  return arr.splice(0);
};

export const isNewTagShow = (globalDocflixConstant, data, isSearch = false) => {
  let opacity = 0;

  if (globalDocflixConstant) {
    if (globalDocflixConstant?.videos?.latestTag?.show) {
      if (data?.latestTag?.show) {
        if (data?.latestTag?.timestamp && data?.latestTag?.timestamp) {
          let t1 = new Date().valueOf();
          let t2 = isSearch
            ? data?.latestTag?.timestamp._seconds * 1000
            : new Date(data?.latestTag?.timestamp.seconds * 1000).valueOf();

          if (t1 < t2) {
            opacity = 1;
          }
        }
      }
    }
  }

  return opacity;
};

// export const scrollTarget = (target) => {
//   scroller.scrollTo(target, {
//     smooth: true,
//     offset: isMobileDevice ? -150 : -100,
//     duration: 700,
//     delay: 100,
//   });
// };

export const scrollTarget = (target) => {
  gsap.to(window, {
    // duration: 5,
    scrollTo: {
      y: target,
      offsetY: 150,
      autoKill: true,
    },
    ease: "power2",
  });
};

export const allSettled = (promises) => {
  return Promise.all(
    promises.map((promise) =>
      promise
        .then((value) => ({ status: "fulfilled", value }))
        .catch((reason) => ({ status: "rejected", reason }))
    )
  );
};

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function transformSearchFilterArray(arr) {
  let result = {};
  arr.forEach(item => {
    result[toTitleCase(`Filter ${item.path}`)] = ArrayJoin(item.values);
  });
  return result;
}

export function isHtmlContentEmpty(htmlString) {
  // Parse the HTML string
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Extract text content and trim whitespace
  const textContent = doc.body.textContent.trim();

  // Check if the resulting text is empty
  return textContent.length === 0;
}
