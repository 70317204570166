// 1. Get users who follows a speaker
// 2. Get speakers followed by user

//Imports
import { SPEAKER_FOLLOWER_COLLECTION } from '../../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const getFollowingUsers = async (speakerId) => {
	try {
		const docRef = firestore.collection(SPEAKER_FOLLOWER_COLLECTION).where('speakerId', '==', speakerId);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});

		const count = query.size;

		return { TotalFollowerCount: count, Followers: _data };
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getFollowedSpeakers = async (userId) => {
	try {
		const docRef = firestore.collection(SPEAKER_FOLLOWER_COLLECTION).where('userId', '==', userId);
		const query = await docRef.get();

		if (query.empty) {
			return [];
		}

		let _data = [];
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});

		return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const get = {
	getFollowingUsers,
	getFollowedSpeakers
};

export default get;
