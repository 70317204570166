import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const useTracking = (trackingId) => {
  const { listen } = useHistory();

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.log(
          "Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
        );
        return;
      }
      window.gtag("config", trackingId, { page_path: location.pathname });
    });

    return unlisten;
  }, [trackingId, listen]);
};

export const useConditionalTracking = (trackingId, path, utm_source = "") => {
  const { listen } = useHistory();
  const { pathname, search = "", hash = "" } = useLocation();
  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      if (!trackingId) {
        console.log(
          "Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`."
        );
        return;
      }
      let __utm_source = new URLSearchParams(search).get("utm_source");
      console.log("----------------------");
      console.log(__utm_source);
      console.log(utm_source);
      console.log(location.pathname);
      console.log("----------------------");
      if (location.pathname == path && __utm_source == utm_source) {
        window.gtag("config", trackingId, { page_path: location.pathname });
      }
    });

    return unlisten;
  }, [trackingId, listen]);
};
