import {
  DIGITAL_AVATAR_CONF_COLLECTION,
  USER_COLLECTION,
} from "../../../AppConstants/CollectionConstants";
import { firestore } from "../../../firebase/firebase";

// const data = {
//     key: 'avatar' || 'cam',
//     cam_image: 'path to image',
//     avatar_settings: {
//         skintone: skintoneid,
//         hairstyle: hairstyleid,
//         clothes: {
//             colorId: colorId,
//             varientId: varientId
//         },
//         accessories: []
//     },
//     avatar_face_img: 'path to file',
//     avatar_full_img: 'path to file'
// }

const createAvatar = async (userId, configuration) => {
  try {
    const docRef = await firestore
      .collection(USER_COLLECTION)
      .doc(userId)
      .get();
    if (!docRef.exists) {
      let err = {
        code: "User not found",
        message: "User doesn't exists",
      };
      throw err;
    }

    return firestore.runTransaction(async (t) => {
      return t.update(firestore.collection(USER_COLLECTION).doc(userId), {
        avatar: { ...configuration, userId: userId },
      });
    });
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const _post = {
  createAvatar,
};

export default _post;
