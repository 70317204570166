export const AlgoliaEventType = {
  View: "viewedObjectIDs",
  Click: "clickedObjectIDs",
  Conversion: "convertedObjectIDs",
  searchClick: "clickedObjectIDsAfterSearch",
  // View: "view",
  // Click: "click",
  // Conversion: "conversion",
};

export const algoliaCustomEvents = {
  normalView: "view",
  normalClick: "click",
  searchView: "search_view",
  searchClick: "search_click",
  conversion: "conversion",
};

export const algoliaIndices = {
  videos: "video_speakers",
};
