import { RESOURCES_COLLECTION } from '../../AppConstants/CollectionConstants';
// import { ResouceType } from '../../AppConstants/TypeConstants';

import firebase, { firestore } from '../../firebase/firebase';

const updateResource = async (resourceId, { title, description, logoUrl, link, type, thumbnailUrl }) => {
	try {
		const docRef = firestore.collection(RESOURCES_COLLECTION).doc(resourceId);

		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No resource found'
				};
				throw err;
			}

			const oldData = doc.data();
			const details = {
				title: title ? title : oldData.title,
				description: description ? description : oldData.description,
				logoUrl: logoUrl ? logoUrl : oldData.logoUrl,
				link: link ? link : oldData.link,
				type: type ? type : oldData.type,
				thumbnailUrl: thumbnailUrl ? thumbnailUrl : oldData.thumbnailUrl,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			};

			transcation.update(docRef, details);
		});
		return resourceId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = {
	updateResource
};

export default put;
