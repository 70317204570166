import clevertap from "clevertap-web-sdk";
import { isProd } from "../utils/common.utils";
clevertap.privacy.push({ optOut: false }); // Set the flag to true, if the user of the device opts out of sharing their data
clevertap.privacy.push({ useIP: true }); // Set the flag to true, if the user agrees to share their IP data
clevertap.init(
    process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID,
    process.env.REACT_APP_CLEVERTAP_REGION
); // Replace with values applicable to you. Refer below
clevertap.spa = true;
clevertap.enablePersonalization = true;
if (!isProd) {
    clevertap.setLogLevel(3);
}
// Here Log Levels is an integer that can be any of the folowing:
//  0: disable all logs
//  1: display only errors
//  2: display errors and info
//  3: display all logs

export default clevertap;