import { QA_COLLECTION } from '../../../AppConstants/CollectionConstants';
import { QA_TYPE } from '../../../AppConstants/TypeConstants';

import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const createQA = async ({ index, question, webinarId, agendaId, userId, name, type, threadId, status }) => {
	try {
		const qaId = uniqid('qa-');

		let qa = {
			index,
			id: qaId,
			question,
			webinarId,
			userId,
			name,
			agendaId,
			status: status,
			totalResponse: 0,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		if (type) {
			switch (type) {
				case QA_TYPE.COMMENT:
					qa.type = QA_TYPE.COMMENT;
					qa.threadId = threadId;
					break;
				case QA_TYPE.SUBTHREAD:
					qa.type = QA_TYPE.SUBTHREAD;
					qa.threadId = threadId;
					break;
				default:
					qa.type = QA_TYPE.MAINTHREAD;
					break;
			}
		}

		await firestore.runTransaction(async (transaction) => {
			transaction.set(firestore.collection(QA_COLLECTION).doc(qaId), qa);
		});

		return qaId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createQA
};

export default post;
