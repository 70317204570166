import _get from './get';
import _post from './post';

import AgendaManager from './agenda';
import PollManager from './polls';
import QAManager from './qa';

const WebinarManager = {
	_get,
	_post,
	AgendaManager,
	PollManager,
	QAManager
};

export default WebinarManager;
