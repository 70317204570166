//Imports
import { USER_METADATA_COLLECTION } from '../../../AppConstants/CollectionConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const updateUserMetadata = async (UserMetaId, { registrationSource, webinars, speakers }) => {
	try {
		const docRef = firestore.collection(USER_METADATA_COLLECTION).doc(UserMetaId);

		await firestore.runTransaction(async (transcation) => {
			const doc = await transcation.get(docRef);
			if (!doc.exists) {
				const err = {
					code: 'NotValidId',
					message: 'No UserMetaData Found'
				};
				throw err;
			}

			const _data = doc.data();

			// Setup User Document
			let UserMeta = {
				webinars: webinars && webinars.length ? webinars : _data.webinars,
				speakers: speakers && speakers.length ? speakers : _data.speakers,
				registrationSource: registrationSource ? registrationSource : _data.registrationSource,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			};
			transcation.update(docRef, UserMeta);
		});

		return UserMetaId;
	} catch (error) {
		console.log('Error:: User:: ', error);
		throw error;
	}
};

const updateIntersets = async (userid, interests) => {
	try {
		const docRef = firestore.collection(USER_METADATA_COLLECTION).doc(userid);

		await firestore.runTransaction(async (transcation) => {
			const doc = await transcation.get(docRef);
			if (!doc.exists) {
				const err = {
					code: 'NotValidId',
					message: 'No UserMetaData Found'
				};
				throw err;
			}
			transcation.update(docRef, {
				areaOfInterest: interests
			});
		});

		return true;
	} catch (error) {
		console.log('Error:: User:: ', error);
		throw error;
	}
}

const put = {
	updateUserMetadata,
	updateIntersets
};

export default put;
