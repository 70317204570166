import _post from './post';
import _put from './put';
import _delete from './delete';

const VideoTranscriptManager = {
	_post,
	_put,
	_delete
};

export default VideoTranscriptManager;
