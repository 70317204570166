//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	RATINGS_COLLECTION,
	WEBINAR_COLLECTION,
	AGENDA_COLLECTION,
	SERIES_COLLECTION,
	SEASON_COLLECTION,
	COURSE_CHAPTERS_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { ContentType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const updateRating = async (targetId, userId, rating, targetType = ContentType.VIDEO) => {
	try {
		const COLLECTION = {
			[ContentType.VIDEO]: VIDEO_COLLECTION,
			[ContentType.WEBINAR]: WEBINAR_COLLECTION,
			[ContentType.AGENDA]: AGENDA_COLLECTION,
			[ContentType.SERIES]: SERIES_COLLECTION,
			[ContentType.SEASON]: SEASON_COLLECTION,
			[ContentType.CHAPTER]: COURSE_CHAPTERS_COLLECTION
		};

		let targetCollection = COLLECTION[targetType];
		const docRef = firestore.collection(targetCollection).doc(targetId);
		const RatingRef = firestore.collection(RATINGS_COLLECTION).doc(`${userId}+${targetId}`);

		const _rating = await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No VideoId Found'
				};
				throw err;
			}

			// if (!doc.data()['ratingsgiven']) {
			// 	let err = {
			// 		code: 'NotValid',
			// 		message: 'No ratings given previously to this asset'
			// 	};

			// 	throw err;
			// }

			let ratingDoc = await transcation.get(RatingRef);
			if (!ratingDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No RatingId Found'
				};
				throw err;
			}

			// Weird logic for rating
			const _doc = doc.data();
			const _rating = ratingDoc.data();

			if (rating > 0) {
				const originalRating = (parseFloat(_doc.rating) || 0) * _doc.ratingsgiven;
				const userRating = _rating.rating;
				const updatedRating = originalRating - userRating + rating;
				const totalUsers = _doc.ratingsgiven;
				const avgRating = updatedRating / totalUsers;

				transcation.update(docRef, {
					rating: parseFloat(avgRating.toFixed(2))
				});

				transcation.update(RatingRef, {
					rating,
					updatetimestamp: firebase.firestore.FieldValue.serverTimestamp()
				});
				return parseFloat(avgRating.toFixed(2));
			} else {
				if (!_doc['ratingsgiven']) {
					let err = {
						code: 'NotValid',
						message: 'No ratings given previously to this asset'
					};

					throw err;
				}

				let userRating = _rating['rating'];

				let currentVideoAvgRating = parseFloat(_doc['rating']) || 0;
				let currentVideoRatingsGiven = _doc['ratingsgiven'];
				let originalRating = currentVideoAvgRating * currentVideoRatingsGiven;

				let updatedVideoRatingsGiven = currentVideoRatingsGiven - 1;
				let updatedTotalVideoRating = originalRating - userRating;
				let updatedVideoAvgRating = updatedTotalVideoRating / updatedVideoRatingsGiven;

				transcation.update(docRef, {
					rating: parseFloat(updatedVideoAvgRating.toFixed(2)),
					ratingsgiven: firebase.firestore.FieldValue.increment(-1)
				});
				transcation.delete(RatingRef);

				return parseFloat(updatedVideoAvgRating.toFixed(2));
			}
		});
		return _rating;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = {
	updateRating
};

export default put;
