export const RootRoute = "/";
export const HOME_ROUTE = "/home";
export const SEARCH_ROUTE = "/search";
export const VIDEO_PAGE_ROUTE = "/videos";
export const PLAYLIST_PAGE_ROUTE = "/myPlaylist";
export const SPEAKER_INFO_ROUTE = "/speakerInfo/:speakerId";
export const VIDEO_DETAIL_ROUTE = "/video/:videoId";
export const SERIES_DETAIL_ROUTE = "/series/:seriesId";
export const WEBINAR_PAGE_ROUTE = "/webinar";
export const ACADEMY_PAGE_ROUTE = "/academy";
export const NETRA_DHARMA_EVENT_PAGE_ROUTE = "/Nethradhama";
export const ACADEMY_DETAIL_ROUTE = "/academy/:academyID";
export const WEBINAR_DETAIL_ROUTE = "/webinar/:webinarID";
export const NOTES_ROUTE = "/notes";
export const REELS_ROUTE = "/reels/:reelsID";
export const REELS_PAGE_ROUTE = "/reels";
export const EMPLOYEE_DASHBOARD_ROUTE = "/employee_dashboard";
export const EMPLOYEE_DASHBOARD_TEAM_ROUTE =
  "/employee_dashboard/teams/:employeeNumber";
export const EMPLOYEE_DASHBOARD_DOCTORS_ROUTE =
  "/employee_dashboard/doctors/:employeeNumber";
export const EMPLOYEE_DASHBOARD_EMPLOYEE_ROUTE =
  "/employee_dashboard/employee/:employeeNumber";
export const OUR_EXPERTS_ROUTE ="/our_expert"
