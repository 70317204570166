import _get from './get';
import _post from './post';
import _put from './put';

// Import manager
import SpeakerFollowManager from './Followers';
const SpeakerManager = {
	_get,
	_post,
	_put,
	SpeakerFollowManager
};

export default SpeakerManager;
