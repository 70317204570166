import React, { useContext, useMemo, useState, useRef, useEffect } from "react";
import styles from "./index.module.scss";
import close from "../../../assets/images/searchClose.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ConstantDataContext } from "../../../contexts/ConstantContext/ConstantDataContextProvider";
import { useLocation } from "react-router-dom";
import filter from "../../../assets/images/filter.png";
import Hits from "../../../scenes/VideoSearchPage/NewHits";
import { VIDEO_SEARCH_CHAR_LIMIT } from "../../../AppConstants/Constants";
import FilterComponent from "../FilterCompo";
import axios from "axios";
import firebase from "firebase";
import Loader from "../../../assets/images/Loader.gif";
import { AnalyticsContext } from "../../../contexts/Analytics/AnalyticsContextProvider";
import { HEADER_SEARCH_CLICK } from "../../../AppConstants/AnalyticsEventName";
import {
  SEARCH_BEST_SHOWS_EVENT,
  SEARCH_TERM_EVENT,
} from "../../../Clevertap/EventNames.ts";
import { getSearchData } from "../../../Clevertap/EventHandler.ts";
import { transformSearchFilterArray } from "../../../utils/common.utils.js";

const defaultLoading = {
  pageLoading: true,
  resultsLoading: false,
  FilterLoading: false,
};

// import Hits from "../Hits"
const ShowsComponent = ({ setValue, addCleverTapAnalytics, tagName, data }) => {
  const history = useHistory();
  const { pathname, search = "", hash = "", state } = useLocation();

  const sortedData = useMemo(
    () => data.sort((a, b) => a.order - b.order),
    [data]
  );

  const handleShowClick = (value) => {
    if (tagName === "Speciality") {
      setValue(value.title);
    } else {
      addCleverTapAnalytics(SEARCH_BEST_SHOWS_EVENT, {
        "Content ID": value.targetID,
        "Content Title": value.title,
        "Content Type": value.type,
      });
      history.push({
        pathname: `/${value.type}/${value.targetID}`,
        search: search,
      });
      // for refresh
      history.go(0);
    }
  };

  return (
    <>
      <div className={styles.showsTitle}>{tagName}</div>
      <div
        className={`${styles.showsContainer} ${tagName === "Best Shows" ? styles.bestShowsContainer : null
          }`}
      >
        {sortedData.map((show) => {
          return (
            show.enable && (
              <div
                key={show.id}
                onClick={() => {
                  if (!show.clickable) {
                    return;
                  }
                  handleShowClick(show);
                }}
                className={styles.ImageContainer}
              >
                <img src={show.image} alt={show.title} />
                {tagName === "Speciality" && (
                  <div className={styles.title}>{show.title}</div>
                )}
              </div>
            )
          );
        })}
      </div>
    </>
  );
};

const SearchModal = ({ handleCloseSearch, Logo }) => {
  const { speakerPage, selectedShow, setSelectedShow } =
    useContext(ConstantDataContext);
  const { addGAWithUserInfo, addCleverTapAnalytics } =
    useContext(AnalyticsContext);
  const history = useHistory();
  let { bestShows, speciality } = speakerPage;
  const [inputValue, setValue] = useState("");

  const [currentQuery, setCurrentQuery] = useState("");
  const [resetValue, setRestValue] = useState(null);
  const { pathname, search = "", hash = "", state } = useLocation();
  const [showPreLoadWrapper, setShowPreLoadWrapper] = useState(true);
  const [showAutoCompleteWrapper, setShowAutoCompleteWrapper] = useState(false);
  const [showFilter, setShowFilterinMob] = useState(false);
  const [searchResult, setSearchResult] = useState({});
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [RecentSearchResult, setRecentSearchResult] = useState();
  const [loading, setLoading] = useState(defaultLoading);
  const [filterValues, setFilterValues] = useState({});
  const shouldWorkSerach = useRef(0);
  const searchTerm = useRef(false);

  useEffect(() => {
    const searches = JSON.parse(localStorage.getItem("searches")) || [];
    setRecentSearchResult(searches);
    getFacets();
    handleSearchTerm();
  }, []);

  useEffect(() => {
    if (inputValue.length < 1 && appliedFilters.length < 1) {
      setSearchResult({});
    }
  }, [inputValue]);

  const getFacets = async () => {
    try {
      const idToken = await firebase.auth().currentUser.getIdToken();

      let config = {
        method: "get",
        url: "https://asia-south1-docflix-d8cab.cloudfunctions.net/MongoDBFacets",
        headers: {
          authorization: idToken,
        },
      };

      let { data } = await axios.request(config);
      Object.keys(data[0].facet).map((key) => {
        data[0].facet[key].hide = false;
      });
      setLoading((prevValue) => ({ ...prevValue, pageLoading: false }));

      setFilterValues(data[0].facet);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchTerm = () => {
    if (search && search.includes("search=")) {
      var searchParam = decodeURIComponent(
        search?.match(/[?&]search=([^&]*)/)[1]
      );
      var replacedSearchParam = searchParam.replaceAll(/%26/g, "&");
      replacedSearchParam.replaceAll("+", " ");
      searchTerm.current = replacedSearchParam;
      handleRecetSearchClick(replacedSearchParam);
    }
  };

  // useEffect(() => {
  //   if (inputValue == "" && !selectedShow) {
  //     setSelectedShow("");
  //   }
  //   const timeout = setTimeout(() => {
  //     if (inputValue.length) {
  //       if (inputValue.length > VIDEO_SEARCH_CHAR_LIMIT) {
  //         let searches = JSON.parse(localStorage.getItem("searches")) || [];
  //         // Add the new search query to the beginning of the array
  //         if (searches.includes(inputValue)) {
  //           searches = searches.filter((search) => search !== inputValue);
  //         }

  //         searches.unshift(inputValue);
  //         // Remove the oldest search if more than 10 searches are stored
  //         if (searches.length > 10) {
  //           searches.pop();
  //         }
  //         // Update local storage
  //         localStorage.setItem("searches", JSON.stringify(searches));
  //         setRecentSearchResult(searches)
  //         getSearchResult(inputValue, appliedFilters);
  //         setShowPreLoadWrapper(false);
  //       } else {
  //         setShowAutoCompleteWrapper(true);
  //         setShowPreLoadWrapper(true);
  //         setRestValue(false);
  //       }
  //     }
  //   }, 1500);
  //   return () => clearTimeout(timeout);
  // }, [inputValue]);

  const handleInputChange = (event) => {
    setValue(event.currentTarget.value);
  };

  const getAutoCompleteSuggestions = async (input) => {
    try {
      // console.log(firebase.auth().currentUser.getIdToken());
      // let data=''
      const idToken = await firebase.auth().currentUser.getIdToken();
      let config = {
        method: "get",
        // maxBodyLength: Infinity,
        url: `https://asia-south1-docflix-d8cab.cloudfunctions.net/MongoDBAutocomplete?search=${input}`,
        // {params}
        headers: {
          authorization: idToken,
        },
        // data : data
      };
      let { data } = await axios.request(config);

      // setFilterValues(data[0].facet)
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchResult = async (input, appliedFilters) => {
    let extraData = { Status: false, message: "" };
    try {
      // todo: fix api hitting multiple times when coming from hot topics
      if (shouldWorkSerach.current !== 0 || !searchTerm.current) {
        setLoading((prevValue) => ({ ...prevValue, resultsLoading: true }));
        let searchData = {
          searchTerm: input,
        };
        if (appliedFilters.length > 0) {
          searchData.filters = appliedFilters;
        }

        const idToken = await firebase.auth().currentUser.getIdToken();
        let config = {
          method: "post",
          // maxBodyLength: Infinity,
          url: `https://asia-south1-docflix-d8cab.cloudfunctions.net/MongoDBSearch`,
          // {params}
          headers: {
            authorization: idToken,
          },
          data: searchData,
        };
        let { data } = await axios.request(config);

        // setFilterValues(data[0].facet)
        let searchResultData = {};
        data.map((result) => {
          if (!searchResultData[result.type]) {
            searchResultData[result.type] = [];
          }

          searchResultData[result.type].push(result);
        });
        if (Object.keys(searchResultData).length < 1) {
          searchResultData.noResult = {};
        }
        extraData["Status"] = true;
        setSearchResult(searchResultData);
        handleAnanlytics(searchData);
        setShowAutoCompleteWrapper(false);
        setLoading((prevValue) => ({
          ...prevValue,
          resultsLoading: false,
        }));
      } else {
        shouldWorkSerach.current = shouldWorkSerach.current + 1;
      }
    } catch (error) {
      extraData["Status"] = false;
      extraData["message"] = error.message;
      console.log(error);
    } finally {
      addCleverTapAnalytics(SEARCH_TERM_EVENT, {
        ...transformSearchFilterArray(appliedFilters), ...getSearchData(
          {
            'Search Term': input,
            Status: extraData["Status"],
            "Status Message": extraData["message"]
          })
      })
    }
  };

  const handleAnanlytics = (searchData) => {
    let data = { ...searchData };
    if (searchTerm.current) {
      data.searchsource = "HotTopics";
    }
    if (searchData?.filters) {
      let filterPath = [];
      let filterVlaues = [];
      searchData.filters.map((filter) => {
        filterPath.push(filter.path);
        filter.values.map((value) => {
          filterVlaues.push(value);
        });
      });
      data.searchFilterPath = filterPath.join(",");
      data.searchFilterValue = filterVlaues.join(",");
      delete data.filters;
    }
    addGAWithUserInfo(HEADER_SEARCH_CLICK, data);
  };

  const handleSubmitSearch = (input = inputValue) => {
    if (input.length > VIDEO_SEARCH_CHAR_LIMIT) {
      let searches = JSON.parse(localStorage.getItem("searches")) || [];
      // Add the new search query to the beginning of the array
      if (searches.includes(input)) {
        searches = searches.filter((search) => search !== input);
      }

      searches.unshift(input);
      // Remove the oldest search if more than 10 searches are stored
      if (searches.length > 10) {
        searches.pop();
      }
      // Update local storage
      localStorage.setItem("searches", JSON.stringify(searches));
      setRecentSearchResult(searches);

      getSearchResult(input, appliedFilters);
      setShowPreLoadWrapper(false);
    } else {
      setShowAutoCompleteWrapper(true);
      setShowPreLoadWrapper(true);
      setRestValue(false);
    }
  };

  const handlehidePreLoadWrapper = () => {
    // if (inputValue.length > VIDEO_SEARCH_CHAR_LIMIT) {
    //   // setShowAutoCompleteWrapper(false);
    // } else if (inputValue.length > 0) {
    //   setShowAutoCompleteWrapper(true);
    // } else {
    //   setShowAutoCompleteWrapper(true);
    // }
    setShowAutoCompleteWrapper(true);
  };

  const handleFilters = (filters) => {
    let newFilters = [];
    Object.keys(filters).map((key) => {
      newFilters.push(filters[key]);
    });
    setAppliedFilters(newFilters);
    if (newFilters.length < 1 && inputValue.length < 1) {
      setSearchResult([]);
    } else {
      getSearchResult(inputValue, newFilters);
    }
  };

  const clearAllRecentSearch = () => {
    localStorage.setItem("searches", JSON.stringify([]));
    setRecentSearchResult([]);
  };

  const deleteSpecificRecentSearch = (deleteSearch) => {
    let searches = RecentSearchResult;

    searches = searches.filter((search) => search !== deleteSearch);
    setRecentSearchResult(searches);

    // Update local storage
    localStorage.setItem("searches", JSON.stringify(searches));
  };

  const handleRecetSearchClick = (input) => {
    setValue(input);
    handleSubmitSearch(input);
  };

  const searchClose = () => {
    if (searchTerm) {
      history.push(`./`);
    }
    handleCloseSearch();
  };

  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchContainer}>
        <div className={styles.DocflixLogo} onClick={searchClose}>
          <Logo />
        </div>
        <div className={styles.searchBoxWrapper}>
          {showAutoCompleteWrapper && (
            <div
              className={styles.closeShowAutoCompleteWrapper}
              onClick={() => setShowAutoCompleteWrapper(false)}
            ></div>
          )}
          <form
            noValidate
            // action=""
            className={styles.inputWrapper}
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmitSearch();
            }}
            role="search"
          >
            <input
              className={styles.input}
              type="search"
              placeholder="Search Videos..."
              value={inputValue}
              onChange={handleInputChange}
              onFocus={handlehidePreLoadWrapper}
            // onBlur={handlehidePreLoadWrapper}
            />
            <svg
              width="19"
              onClick={() => handleSubmitSearch()}
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.21031 15.9379C12.1871 15.9379 15.4108 12.7142 15.4108 8.7374C15.4108 4.76066 12.1871 1.53687 8.21031 1.53687C4.23356 1.53687 1.00977 4.76066 1.00977 8.7374C1.00977 12.7142 4.23356 15.9379 8.21031 15.9379Z"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.2107 17.7378L13.2954 13.8225"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </form>

          <img
            className={styles.filter}
            onClick={() => setShowFilterinMob(true)}
            style={{
              cursor: "pointer",
            }}
            src={filter}
            alt="filter"
          />
          <img
            className={styles.close}
            onClick={searchClose}
            style={{
              cursor: "pointer",
            }}
            src={close}
            alt="close"
          />
          {showAutoCompleteWrapper && RecentSearchResult.length > 0 && (
            <div className={styles.autoComplete}>
              {/* <h4 className={styles.heading}>recent search</h4> */}
              {/* <div className={styles.topHeader}>
                <h4>Recent searches</h4>
                <button onClick={clearAllRecentSearch}>Clear all</button>
              </div> */}
              {RecentSearchResult?.map((search, index) => (
                <div key={index}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="18"
                    viewBox="0 0 21 18"
                    fill="none"
                    className={styles.history}
                  >
                    <path
                      d="M12.5 5H11V10L15.28 12.54L16 11.33L12.5 9.25V5ZM12 0C9.61305 0 7.32387 0.948211 5.63604 2.63604C3.94821 4.32387 3 6.61305 3 9H0L3.96 13.03L8 9H5C5 7.14348 5.7375 5.36301 7.05025 4.05025C8.36301 2.7375 10.1435 2 12 2C13.8565 2 15.637 2.7375 16.9497 4.05025C18.2625 5.36301 19 7.14348 19 9C19 10.8565 18.2625 12.637 16.9497 13.9497C15.637 15.2625 13.8565 16 12 16C10.07 16 8.32 15.21 7.06 13.94L5.64 15.36C6.47147 16.2006 7.46218 16.8671 8.5542 17.3203C9.64622 17.7736 10.8176 18.0047 12 18C14.3869 18 16.6761 17.0518 18.364 15.364C20.0518 13.6761 21 11.3869 21 9C21 6.61305 20.0518 4.32387 18.364 2.63604C16.6761 0.948211 14.3869 0 12 0Z"
                      fill="#787878"
                    />
                  </svg>
                  {search.toLocaleLowerCase().includes(inputValue) &&
                    inputValue.length > 0 ? (
                    <h4 onClick={() => handleRecetSearchClick(search)}>
                      {search.toLocaleLowerCase().split(inputValue)[0]}
                      <span className={styles.highlighted}>{inputValue}</span>
                      {search.toLocaleLowerCase().split(inputValue)[1]}
                    </h4>
                  ) : (
                    <h4 onClick={() => handleRecetSearchClick(search)}>
                      {search}
                    </h4>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    className={styles.close}
                    onClick={() => deleteSpecificRecentSearch(search)}
                  >
                    <path
                      d="M2 2L18 18"
                      stroke="white"
                      stroke-width="3.04762"
                      stroke-linecap="round"
                    />
                    <path
                      d="M18 2L2 18"
                      stroke="white"
                      stroke-width="3.04762"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              ))}
            </div>
          )}
        </div>

        {loading.pageLoading ? (
          <img className={styles.loading} src={Loader} />
        ) : (
          <div className={styles.showsAndFilterWrapper}>
            {loading.resultsLoading ? (
              <img className={styles.loading} src={Loader} />
            ) : (
              <>
                {Object.keys(searchResult).length < 1 ? (
                  currentQuery.length <= 0 && (
                    <div className={styles.showsWrapper}>
                      <ShowsComponent
                        setValue={setSelectedShow}
                        addCleverTapAnalytics={addCleverTapAnalytics}
                        tagName="Best Shows"
                        data={bestShows}
                      />
                    </div>
                  )
                ) : (
                  <div className={styles.resultWrapper}>
                    {searchResult?.noResult ? (
                      <h4 className={styles.noResult}>No Result Available</h4>
                    ) : (
                      Object.keys(searchResult).map((key) => {
                        if (key.toLowerCase() == "podcast") {
                          if (Object.keys(searchResult).length < 2) {
                            return (
                              <h4 className={styles.noResult}>
                                No Result Available
                              </h4>
                            );
                          }
                          return <></>;
                        } else {
                          return (
                            <Hits
                              key={key}
                              title={key}
                              inputValue={inputValue}
                              appliedFilters={appliedFilters}
                              results={searchResult[key]}
                            />
                          );
                        }
                      })
                    )}
                  </div>
                )}
              </>
            )}

            <FilterComponent
              showFilterTab={showFilter}
              setShowFilterTab={() => setShowFilterinMob(false)}
              submitFilter={handleFilters}
              filters={filterValues}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
