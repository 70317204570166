import {
	AGENDA_COLLECTION,
	LIKES_COLLECTION,
	USER_COLLECTION,
	VIDEO_COLLECTION,
	WEBINAR_COLLECTION
} from '../../AppConstants/CollectionConstants';
import { ScorePoints } from '../../AppConstants/ScorePoints';
import { ContentType, LikeType, LIKE_ACTION_TYPE } from '../../AppConstants/TypeConstants';
import firebase, { firestore } from '../../firebase/firebase';

const COLLECTION = {
	[ContentType.VIDEO]: VIDEO_COLLECTION,
	[ContentType.WEBINAR]: WEBINAR_COLLECTION,
	[ContentType.AGENDA]: AGENDA_COLLECTION
};

const getLike = (likeType = ContentType.VIDEO, targetId = '', userId = '') => {
	return new Promise(async (res, rej) => {
		try {
			const LikeRef = firestore.collection(LIKES_COLLECTION).doc(`${userId}+${targetId}`);
			let likeDoc = await LikeRef.get();
			if (likeDoc.exists) {
				res(likeDoc.data());
			} else {
				res(null);
			}
		} catch (error) {
			rej(error);
		}
	});
};

const postLike = (likeType = ContentType.VIDEO, targetId = '', userId = '') => {
	return new Promise(async (res, rej) => {
		try {
			let targetCollection = COLLECTION[likeType];

			const docRef = firestore.collection(targetCollection).doc(targetId);
			const LikeRef = firestore.collection(LIKES_COLLECTION).doc(`${userId}+${targetId}`);

			const userRef = firestore.collection(USER_COLLECTION).doc(userId);

			const like = await firestore.runTransaction(async (transcation) => {
				let doc = await transcation.get(docRef);
				let likeDoc = await transcation.get(LikeRef);
				let userdoc = await transcation.get(userRef);

				if (!doc.exists) {
					let err = {
						code: 'NotValidId',
						message: 'No Target Found'
					};
					throw err;
				}

				if (!userdoc.exists) {
					let err = {
						code: 'NotValidId',
						message: 'No User Found'
					};
					throw err;
				}
				if (!likeDoc.exists) {
					let newData = {
						id: `${userId}+${targetId}`,
						targetId: targetId,
						type: likeType,
						user: userId,
						actionType: LIKE_ACTION_TYPE.LIKE,
						timestamp: firebase.firestore.FieldValue.serverTimestamp(),
						points: ScorePoints.LIKE,
						date: new Date().getTime()
					};
					transcation.set(LikeRef, newData);

					transcation.update(docRef, {
						likes: firebase.firestore.FieldValue.increment(1)
					});

					// Adding Points for like
					transcation.update(userRef, {
						score: userdoc.data().score
							? firebase.firestore.FieldValue.increment(Math.abs(ScorePoints.LIKE))
							: ScorePoints.LIKE
					});

					let oldLike = doc.data().likes;
					return oldLike && oldLike ? oldLike + 1 : 0;
				} else {
					throw {
						code: 'Already Liked',
						message: 'Action can not be performed, already liked'
					};
				}
			});
			res(like);
		} catch (error) {
			rej(error);
		}
	});
};

const deleteLike = async (likeType = ContentType.VIDEO, targetId, userId) => {
	try {
		let targetCollection = COLLECTION[likeType];

		const docRef = firestore.collection(targetCollection).doc(targetId);

		const LikeRef = firestore.collection(LIKES_COLLECTION).doc(`${userId}+${targetId}`);

		const userRef = firestore.collection(USER_COLLECTION).doc(userId);

		const count = await firestore.runTransaction(async (transaction) => {
			let mainDoc = await transaction.get(docRef);
			let likeDoc = await transaction.get(LikeRef);
			let userdoc = await transaction.get(userRef);

			if (!likeDoc.exists) {
				let err = {
					code: 'NoSuchDocFound',
					message: 'NoSuchDocFound'
				};
				throw err;
			}

			if (!userdoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No User Found'
				};
				throw err;
			}

			transaction.update(docRef, {
				likes: firebase.firestore.FieldValue.increment(-1)
			});
			transaction.delete(LikeRef);

			// Removing Points for like
			if (likeDoc.data().points) {
				transaction.update(userRef, {
					score: firebase.firestore.FieldValue.increment(-Math.abs(likeDoc.data().points))
				});
			}

			let oldLike = mainDoc.data().likes;
			return oldLike && oldLike !== 0 ? oldLike - 1 : 0;
		});
		return count;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const LikeManager = {
	postLike,
	getLike,
	deleteLike
};
export default LikeManager;
