
import { RATINGS_COLLECTION } from '../../../AppConstants/CollectionConstants';
import firebase, { firestore } from '../../../firebase/firebase';

const getRating = async (userId) => {
    try {
        const docRef = firestore.collection(RATINGS_COLLECTION).where("user", "==", userId);
        const response = await docRef.get()
        let res_arr = {}
        if (!response.empty) {
            response.docs.forEach(doc => {
                res_arr[doc.data().targetId] = doc.data().rating
            })
        }
        return res_arr;
    } catch (error) {
        console.log('Error :: ', error);
        throw error;
    }
};

const _get = {
    getRating
};

export default _get;
