import {
  WEBINAR_ATTENDED_COLLECTION,
  WEBINAR_COLLECTION,
} from "../../AppConstants/CollectionConstants";
import { SourceType } from "../../AppConstants/TypeConstants";
import firebase, { firestore } from "../../firebase/firebase";
import { getSystemID } from "../../utils/getPlatformInfo";
import ScoreManager from "../ScoringManager/post";

const uniqid = require("uniqid");

const createWebinar = async ({
  title,
  description,
  detailsId,
  speakers,
  tags,
  resources,
  thumbnailUrl,
  sourceType,
  sourceData,
  agendas,
  startTime,
  endTime,
  status,
}) => {
  try {
    const webinarId = uniqid("webinar-");

    // Formatting tags and speakers
    tags = tags ? tags.map((t) => t.toLowerCase()) : [];
    speakers = speakers ? speakers : [];
    resources = resources ? resources.map((t) => t.toLowerCase()) : [];

    let webinar = {
      id: webinarId,
      title,
      description,

      detailsId,
      speakers,
      tags,
      resources,
      thumbnailUrl,

      agendas,
      startTime,
      endTime,

      rating: 0,
      ratingsgiven: 0,
      status: status ? status : "disabled",
      ongoingagendaid: 0,
      likes: 0,
      attendenceCount: 0,

      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // This flow is stupid needs to be changed - ## important note
    if (sourceType && sourceType === SourceType.SOURCE_MUX) {
      webinar = {
        sourceType,
        livestreamId: sourceData.livestreamId,
        playbackId: sourceData.playbackId,
        videoPolicy: sourceData.videoPolicy,
        ...webinar,
      };
    }

    await firestore.runTransaction(async (transaction) => {
      transaction.set(
        firestore.collection(WEBINAR_COLLECTION).doc(webinarId),
        webinar
      );
    });

    return webinarId;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const webinarTimeSpentAnalytics = async (
  userID,
  webinarID,
  agendaID,
  nextAgendaItemTime
) => {
  return new Promise(async (res, rej) => {
    try {
      let docId = `${webinarID}_${userID}`;
      const webinarTimespentRef = firestore
        .collection(WEBINAR_ATTENDED_COLLECTION)
        .doc(docId);

      // next agenda start time else webinar end time
      let timestamp = nextAgendaItemTime; // new Date(nextAgendaItemTime);

      ScoreManager.watchedWebinar(webinarID, userID);
      await firestore.runTransaction(async (transaction) => {
        let doc = await transaction.get(webinarTimespentRef);

        let serverTimeStamp = firebase.firestore.Timestamp.now();

        let systemID = getSystemID();

        if (!doc.exists) {
          transaction.set(webinarTimespentRef, {
            data: firebase.firestore.FieldValue.arrayUnion(agendaID),
            id: docId,
            userID: userID,
            webinarID: webinarID,
            timestamp: serverTimeStamp,
            timeSessionData: [
              {
                agendaID: agendaID,
                startTime: serverTimeStamp,
                endTime: timestamp,
                systemID: systemID,
              },
            ],
          });
          res();

          return;
        }
        let previousDoc = await transaction.get(webinarTimespentRef);
        let previousData = previousDoc.data();
        let previousAgendaIndex = previousData?.timeSessionData?.findIndex(
          (d) => d.agendaID === agendaID && d.systemID === systemID
        );

        // if agenda id already exists
        if (previousData.timeSessionData && previousAgendaIndex !== -1) {
          previousData.timeSessionData[previousAgendaIndex] = {
            ...previousData.timeSessionData[previousAgendaIndex],
            endTime: timestamp,
          };

          transaction.update(webinarTimespentRef, {
            timeSessionData: previousData.timeSessionData,
          });
        } else {
          transaction.update(webinarTimespentRef, {
            data: firebase.firestore.FieldValue.arrayUnion(agendaID),
            timeSessionData: firebase.firestore.FieldValue.arrayUnion({
              agendaID: agendaID,
              startTime: serverTimeStamp,
              endTime: timestamp,
              systemID: systemID,
            }),
          });
        }
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

const post = {
  createWebinar,
  webinarTimeSpentAnalytics,
};

export default post;
