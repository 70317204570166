//Imports
import {
	SPEAKERS_COLLECTION,
	SPEAKER_FOLLOWER_COLLECTION,
	USER_METADATA_COLLECTION
} from '../../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const addFollower = async (speakerId, userId) => {
	try {
		const id = speakerId + '+' + userId;
		const docRef = firestore.collection(SPEAKER_FOLLOWER_COLLECTION).doc(id);
		const speakerRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);
		const userMetadataRef = firestore.collection(USER_METADATA_COLLECTION).doc('metadata-' + userId);

		await firestore.runTransaction(async (transcation) => {
			let speaker = await transcation.get(speakerRef);
			let userMetadata = await transcation.get(userMetadataRef);
			if (!speaker.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No speaker found'
				};
				throw err;
			}

			if (!userMetadata.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No metadata found'
				};
				throw err;
			}

			const _data = {
				id,
				userId,
				speakerId,
				timestamp: firebase.firestore.FieldValue.serverTimestamp()
			};

			transcation.set(docRef, _data);
			transcation.update(speakerRef, {
				followerCount: firebase.firestore.FieldValue.increment(1),
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			});
			transcation.update(userMetadataRef, {
				speakers: firebase.firestore.FieldValue.arrayUnion(speakerId),
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			});
		});
		return id;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	addFollower
};

export default post;
