//Imports
import { CloudFunctionName } from '../../AppConstants/CloudFunctionName';
import { VIDEO_COLLECTION, LIKES_COLLECTION, SPEAKERS_COLLECTION } from '../../AppConstants/CollectionConstants';
import { SourceType, VideoType } from '../../AppConstants/TypeConstants';

// Firebase
import firebase, { cloudFunction_as, firestore } from '../../firebase/firebase';
const uniqid = require('uniqid');

const fetchMuxData = cloudFunction_as.httpsCallable(CloudFunctionName.GET_MUXDATA_ASSETID);

const updateVideo = async (
	videoId,
	{
		// title,
		// description,
		tags,
		// speakers,
		// detailsId,
		// featuredIndex,
		misc,
		topics,
		speciality,
		therapy
		// associationType,
		// associationId,
		// type
	}
) => {
	if (!videoId) throw new Error('videoId undefined!');
	try {
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No videoID Found'
				};
				throw err;
			}
			let oldData = doc.data();
			const smallTag = tags ? tags.map((t) => t.toLowerCase()) : null;
			// const _speakers = speakers ? speakers : null;
			therapy = therapy ? therapy.map((t) => t.toLowerCase()) : null;
			misc = misc ? misc.map((t) => t.toLowerCase()) : null;
			topics = topics ? topics.map((t) => t.toLowerCase()) : null;
			speciality = speciality ? speciality.map((t) => t.toLowerCase()) : null;

			transcation.update(docRef, {
				// title: title ? title : oldData.title,
				// description: description ? description : oldData.description,
				// type: type || oldData.type,
				// speakers: _speakers ? _speakers : oldData.speakers,
				tags: smallTag ? smallTag : oldData.tags,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
				// detailsId: detailsId ? detailsId : oldData.detailsId,
				// featuredIndex: featuredIndex !== undefined ? featuredIndex : oldData.featuredIndex,
				speciality: speciality ? speciality : oldData.speciality,
				therapy: therapy ? therapy : oldData.therapy,
				misc: misc ? misc : oldData.misc,
				topics: topics ? topics : oldData.topics,
				metaTags: [ ...tags, ...therapy, ...misc, ...topics, ...speciality ]
				// associationType: associationType ? associationType : oldData.associationType,
				// associationId: associationId ? associationId : oldData.associationId
			});
		});
		return videoId;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const addVideoTags = async (videoId, tag) => {
	try {
		const ref = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(ref);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No EventId Found'
				};
				throw err;
			}
			transcation.update(ref, {
				tags: firebase.firestore.FieldValue.arrayUnion(tag.toLowerCase())
			});
		});
		return;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const removeVideoTags = async (videoId, tag) => {
	try {
		const ref = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(ref);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No videoId Found'
				};
				throw err;
			}
			transcation.update(ref, {
				tags: firebase.firestore.FieldValue.arrayRemove(tag.toLowerCase())
			});
		});
		return;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const addVideoSpeakers = async (videoId, speakerId) => {
	try {
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No videoID Found'
				};
				throw err;
			}
			transcation.update(docRef, {
				speakers: firebase.firestore.FieldValue.arrayUnion(speakerId)
			});
		});
		return;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const removeVideoSpeakers = async (videoId, speakerId) => {
	try {
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No videoID Found'
				};
				throw err;
			}
			transcation.update(docRef, {
				speakers: firebase.firestore.FieldValue.arrayRemove(speakerId)
			});
		});

		return;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

export const updateVideoTokens = async (tokens, videoId) => {
	try {
		tokens = JSON.parse(tokens);
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		await firestore.runTransaction(async (transaction) => {
			const doc = await transaction.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No videoId Found'
				};
				throw err;
			}
			// console.log('bro wth');
			transaction.update(docRef, {
				tokens: tokens,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			});
			console.log('Tokens updated');
			// console.log('DONEEEEE updating tokens bro');
		});

		await fetchMuxData(videoId);

		return;
	} catch (error) {
		console.log('Error :: updating tokens :: ', error);
		return;
	}
};

const put = { updateVideo, addVideoTags, removeVideoTags, addVideoSpeakers, removeVideoSpeakers };

export default put;
