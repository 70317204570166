//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	DISLIKES_COLLECTION,
	SPEAKERS_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { SourceType, VideoType, LikeType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const deleteLike = async (videoId, userId, dislike = false) => {
	try {
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(videoId);
		const LikeRef = firestore
			.collection(dislike ? DISLIKES_COLLECTION : LIKES_COLLECTION)
			.doc(`${userId}+${videoId}`);
		const count = await firestore.runTransaction(async (transaction) => {
			let likeDoc = await transaction.get(LikeRef);
			if (!likeDoc.exists) {
				let err = {
					code: 'NoSuchDocFound',
					message: 'NoSuchDocFound'
				};
				throw err;
			}

			let mainDoc = await transaction.get(docRef);
			transaction.delete(LikeRef);
			if (dislike) {
				transaction.update(docRef, {
					dislikes: firebase.firestore.FieldValue.increment(-1)
				});
				// console.log(mainDoc.data())
				return mainDoc.data().dislikes ? mainDoc.data().dislikes - 1 : 0;
			} else {
				transaction.update(docRef, {
					likes: firebase.firestore.FieldValue.increment(-1)
				});
				// console.log(mainDoc.data())
				return mainDoc.data().likes ? mainDoc.data().likes - 1 : 0;
			}
		});
		return count;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const _delete = {
	deleteLike
};

export default _delete;
