export const CloudFunctionName = {
  UPDATE_USER_STATUS: "updateUserStatus",
  USER_CREATION_DEV: "userCreationLocation",
  GET_LOCATION_DATA: "getLocationData",
  GET_MUXDATA_ASSETID: "getMuxDataWithAssetId",
  WebinarRegister: "webinarRegister",
  // CREATE_WEBINAR: "createWebinar",
  MAIL_SERVICE: "mailService",
  USER_EXISTS: "checkUserExists",
  REALTIME_METRICS: "getRealtimeWebinarMetrics",
  // CREATE_LIVESTREAM: "createLivestream",
  USER_TOKEN_UPDATE: "userTokenUpdate",
  ADD_NOTIFICATION_TO_REALTIME_DB: "addNotificationToRealTimeDB",
  UPDATE_NOTIFICATION_TO_REALTIME_DB: "updateNotificationToRealTimeDB",
  USER_PROFILE_UPDATE: "userUpdateLocation",
  GET_GLOBAL_ATTRIBUTES: "getGlobalAttributes", //no user required
  VERIFY_OTP: "verifyOTPV3",
  SEND_OTP: "sendOTPV3",
  FETCH_USERS_USERS_IN_AREA: "fetchUsersInArea",
  sendNotificationToTopic: "sendNotificationToTopic",
  sendNotificationToTopic2: "sendNotificationToTopic2",
  subscribeAllTokens: "subscribeAllTokens",
  addLiveReaction: "addLiveReaction",
  popLiveReaction: "popLiveReaction",
  VERIFY_EMPLOYEE_VIDEO: "privateSeasonCode",

  // Courses - Academy
  COURSE_ASSESSMENT_SUBMIT: "submitCourseAssesmentv3",
  GENERATE_COURSE_CERTIFICATE: "generateLMSCertificate",
  // GENERATE_VIDEO_TOKEN_WITHOUT_USER: "generateVideoToken",
  GENERATE_VIDEO_TOKEN_WITHOUT_USER: "generateVideoTokenDev",
};
