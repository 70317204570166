import { isMobileDevice } from "../../utils/getPlatformInfo";
import styles from "./index.module.scss";

export const Circle = ({ radius }) => {
  return (
    <div
      className={`${styles.circle} ${styles.shine}`}
      style={{ height: radius, width: radius }}
    />
  );
};

export const Box = ({ height, width, marginRight = 0 }) => {
  return (
    <div
      className={`${styles.box} ${styles.shine}`}
      style={{
        height: isMobileDevice ? height / 2 : height,
        width: isMobileDevice
          ? typeof width === "string"
            ? width
            : width / 2
          : width,
        marginRight: marginRight,
      }}
    />
  );
};

export const ContainerBox = ({
  children,
  height = "100%",
  width = "100%",
  shimmer = true,
  style = {},
}) => {
  return (
    <div
      className={`${styles.box} ${
        shimmer ? styles.shine : styles.shinelessBox
      }`}
      style={{
        height: height,
        width: width,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const Line = ({ height, width, count = 1, sameWidth = true }) => {
  const lineWidth = isMobileDevice ? width / 4 : width;
  return (
    <div className={styles.lines}>
      {[...Array(count)].map((count, key) => {
        return (
          <div
            key={key}
            className={`${styles.line} ${styles.shine}`}
            style={{
              height: isMobileDevice ? height / 2 : height,
              width: sameWidth ? lineWidth : lineWidth / 2 + (key % 2) * 100,
              marginTop: isMobileDevice ? height : height * 2,
            }}
          />
        );
      })}
    </div>
  );
};

export const Shimmer = ({
  loading = false,
  loadingComponent,
  renderComponent,
}) => {
  // const [isLoading, setIsLoading] = useState(loading);
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     loadingSecs && setIsLoading(false);
  //   }, loadingSecs);
  //   return () => clearTimeout(timeoutId);
  // });
  return loading ? loadingComponent : renderComponent;
};
