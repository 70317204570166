//Imports
import {
  SERIES_COLLECTION,
  MEDIA_METADATA_COLLECTION,
} from "../../../AppConstants/CollectionConstants";
import {
  ContentType,
  ExclusiveType,
} from "../../../AppConstants/TypeConstants";

// Firebase
import firebase, { firestore } from "../../../firebase/firebase";
const uniqid = require("uniqid");

const createSeries = async ({
  title,
  description,
  type,
  tags,
  speakers,
  detailsId,
  thumbnailUrl,
  featuredIndex,
  seasons,
  therapy,
  associationType,
  associationId,
}) => {
  try {
    const seriesId = uniqid("series-");
    const metaDataId = uniqid("media-metadata-");

    // formatting tags and speakers
    tags = tags ? tags.map((t) => t.toLowerCase()) : [];
    speakers = speakers ? speakers : [];
    therapy = therapy ? therapy.map((t) => t.toLowerCase()) : [];

    let series = {
      title,
      description,
      type: type ? type : ExclusiveType.FREE,
      id: seriesId,
      metaDataId,

      speakers: speakers || [],
      tags: [...tags, ...therapy, type],

      likes: 0,
      views: 0,
      detailsId: detailsId || null,
      rating: 0,
      ratingsgiven: 0,
      featuredIndex: featuredIndex ? featuredIndex : -1,

      thumbnailUrl: thumbnailUrl || null,
      seasons: seasons && seasons.length ? seasons : [],

      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let metaData = {
      id: metaDataId,
      targetId: seriesId,
      contentType: type ? type : ExclusiveType.FREE,
      mediaType: ContentType.SERIES,

      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      detailsId: detailsId || null,

      therapy: therapy || [],
      tags: [...tags, ...therapy], //contains tags and therapy

      Thumbnails: {
        small:
          "https://storage.googleapis.com/cipla-impact.appspot.com/impact2021/video/1.jpg",
        medium:
          "https://storage.googleapis.com/cipla-impact.appspot.com/impact2021/video/2.jpg",
        large:
          "https://storage.googleapis.com/cipla-impact.appspot.com/impact2021/video/3.jpg",
      },

      title: title,
      description: description,

      associationType: associationType || null,
      associationId: associationId || null,
    };

    await firestore.runTransaction(async (transaction) => {
      transaction.set(
        firestore.collection(SERIES_COLLECTION).doc(seriesId),
        series
      );
      transaction.set(
        firestore.collection(MEDIA_METADATA_COLLECTION).doc(metaDataId),
        metaData
      );
    });

    return seriesId;
  } catch (error) {
    console.error("Error :: ", error);
    throw error;
  }
};

const post = {
  createSeries,
};

export default post;
