//Imports
import axios from 'axios';
import { USER_COLLECTION, USER_METADATA_COLLECTION } from '../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../firebase/firebase';

let userListenerRef = null;

const getUser = async (userId) => {
	try {
		const docRef = firestore.collection(USER_COLLECTION).doc(userId);
		let doc = await docRef.get();
		if (!doc.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No User found'
			};
			throw err;
		}

		return {
			...doc.data(),
			userid: doc.id
		};
		// const _data = await firestore.runTransaction(async (transcation) => {
		//   let doc = await transcation.get(docRef);
		//   if (!doc.exists) {
		//     let err = {
		//       code: "NotValidId",
		//       message: "No User found",
		//     };
		//     throw err;
		//   }

		//   return {
		//     ...doc.data(),
		//     userid: doc.id,
		//   };
		// });
		// return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const attachUserListener = (userID, callback = () => console.log('noFunFound')) => {
	const ref = firestore.collection(USER_COLLECTION).doc(userID);
	userListenerRef = ref.onSnapshot(
		(query) => {
			if (!query.exists) {
				callback(null);
			}
			let _data = query.data();
			callback(_data);
		},
		(err) => {
			callback(null, err);
		}
	);
};

const removeUserListener = () => {
	if (userListenerRef) {
		userListenerRef();
	}
};

const verifyDoctor = async (uid, data, instituteIndex, plan) => {
	try {
		const URL = `https://www.nmc.org.in/MCIRest/open/getPaginatedData?service=getPaginatedDoctor&draw=1&columns%5B0%5D%5Bdata%5D=0&columns%5B0%5D%5Bname%5D=&columns%5B0%5D%5Bsearchable%5D=true&columns%5B0%5D%5Borderable%5D=true&columns%5B0%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B0%5D%5Bsearch%5D%5Bregex%5D=false&colum	ns%5B1%5D%5Bdata%5D=1&columns%5B1%5D%5Bname%5D=&columns%5B1%5D%5Bsearchable%5D=true&columns%5B1%5D%5Borderable%5D=true&columns%5B1%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B1%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B2%5D%5Bdata%5D=2&columns%5B2%5D%5Bname%5D=&columns%5B2%5D%5Bsearchable%5D=true&columns%5B2%5D%5Borderable%5D=true&columns%5B2%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B2%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B3%5D%5Bdata%5D=3&columns%5B3%5D%5Bname%5D=&columns%5B3%5D%5Bsearchable%5D=true&columns%5B3%5D%5Borderable%5D=true&columns%5B3%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B3%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B4%5D%5Bdata%5D=4&columns%5B4%5D%5Bname%5D=&columns%5B4%5D%5Bsearchable%5D=true&columns%5B4%5D%5Borderable%5D=true&columns%5B4%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B4%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B5%5D%5Bdata%5D=5&columns%5B5%5D%5Bname%5D=&columns%5B5%5D%5Bsearchable%5D=true&columns%5B5%5D%5Borderable%5D=true&columns%5B5%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B5%5D%5Bsearch%5D%5Bregex%5D=false&columns%5B6%5D%5Bdata%5D=6&columns%5B6%5D%5Bname%5D=&columns%5B6%5D%5Bsearchable%5D=true&columns%5B6%5D%5Borderable%5D=true&columns%5B6%5D%5Bsearch%5D%5Bvalue%5D=&columns%5B6%5D%5Bsearch%5D%5Bregex%5D=false&order%5B0%5D%5Bcolumn%5D=0&order%5B0%5D%5Bdir%5D=asc&start=0&length=1000&search%5Bvalue%5D=&search%5Bregex%5D=false&name=&registrationNo=${data.regId}&smcId=${instituteIndex}&year=${data.year}&_=1623734232536`;
		const response = await axios.get(URL);

		if (response.status === 200) {
			if (response.data.recordsFiltered <= 0) {
				throw new Error('No Match Found');
			} else {
				const userDocRef = firestore.collection(USER_COLLECTION).doc(uid);
				const userMetadataDocRef = firestore.collection(USER_METADATA_COLLECTION).doc(uid);
				await firestore.runTransaction(async (transcation) => {
					transcation.update(userDocRef, {
						...data,
						doctorVerifiedTime: firebase.firestore.Timestamp.now()
					});
					transcation.update(userMetadataDocRef, {
						current_plan: plan
					});
				});
				return true;
				// return firestore
				//   .collection(USER_COLLECTION)
				//   .doc(uid)
				//   .update({
				//     ...data,
				//     doctorVerifiedTime: firebase.firestore.Timestamp.now(),
				//   });
			}
		} else {
			throw new Error('Internal Server Error.');
		}
	} catch (error) {
		throw new Error('No Match Found');
	}
};

const get = {
	getUser,
	attachUserListener,
	removeUserListener,
	verifyDoctor
};

export default get;
