//Imports
import {
	BACKSTAGE_COLLECTION,
	BACKSTAGE_COLLECTION_OUR_EXPERTS,
	SPEAKERS_COLLECTION
} from '../../AppConstants/CollectionConstants';
import { ASCENDING, DESCENDING, TIMESTAMP } from '../../AppConstants/SortConstants';
import { ContentType } from '../../AppConstants/TypeConstants';
// Firebase
import firebase, { database, firestore } from '../../firebase/firebase';

const getSpeakerById = async (speakerId) => {
	try {
		const docRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);
		const doc = await docRef.get();
		if (!doc.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No details found'
			};
			throw err;
		}

		return doc.data();
		// const _data = await firestore.runTransaction(async (transcation) => {
		//   let doc = await transcation.get(docRef);
		//   if (!doc.exists) {
		//     let err = {
		//       code: "NotValidId",
		//       message: "No details found",
		//     };
		//     throw err;
		//   }

		//   return doc.data();
		// });
		// return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const getAllSpeakers = async ({
	based_on = TIMESTAMP,
	order = DESCENDING,
	lastDocRef = null,
	limit = 10,
	ignoreLimit = false,
	data = []
	// featuredIndex = false,
}) => {
	try {
		let docRef = firestore.collection(SPEAKERS_COLLECTION);

		docRef = docRef.orderBy(based_on, order);
		// docRef = docRef.orderBy("featuredIndex", "desc");

		if (!ignoreLimit) {
			docRef = docRef.limit(limit);
		}

		// Pagination
		if (lastDocRef) {
			docRef = docRef.startAfter(lastDocRef);
		}
		const query = await docRef.get();

		// Updating last docRef
		lastDocRef = query.docs[query.docs.length - 1];

		if (query.empty) {
			return [];
		}

		let _data = data;
		query.docs.forEach((doc) => {
			_data.push(doc.data());
		});
		return { data: _data, lastDocRef };
	} catch (error) {
		console.error('ERROR IS AT OUR SPEAKER', { error });
	}
};

const getSpeakersFromBackstage = async () => {
	try {
		const docRef = firestore.collection(BACKSTAGE_COLLECTION).doc(BACKSTAGE_COLLECTION_OUR_EXPERTS);
		const docData = await docRef.get();
		if (!docData.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No Document found'
			};
			return { data: [] };
			// throw err;
		}

		let data = docData.data().list;

		if (!data.length) {
			return { data: [] };
		}

		data = data.map((doc) => doc.get());
		data = await Promise.allSettled(data);

		let _data = data.map((doc) => doc.status === 'fulfilled' && doc.value.data());
		return { data: _data };
	} catch (error) {
		console.error(error);
		return { data: [] };
		// throw error;
	}
};

const getExtraDataFromRDB = async (collection, ids, field) => {
	let promiseArr = [];
	if (!ids || !ids.length) {
		return promiseArr;
	  }
	for (let id of ids) {
		promiseArr.push(appendData(collection, id, field));
	}

	let resp = await Promise.allSettled(promiseArr);
	let response = [];
	resp.forEach((doc) => {
		if (doc.status === 'fulfilled') {
			if (doc.value.exists()) {
				response.push(doc.value.val());
			}
		}
	});

	return response;
};

const appendData = (collection, id, field) => {
	return database.ref('content').child(collection).child(id).child(field).once('value');
};

const get = {
	getSpeakerById,
	getAllSpeakers,
	getSpeakersFromBackstage,
	getExtraDataFromRDB
};

export default get;
