import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import SpeakerManager from "../../Managers/Speakers";

const OUR_EXPERT_ITEM_PER_PAGE = 20;

const OurExpertsContext = createContext();

export const useOurExperts = () => useContext(OurExpertsContext);

const OurExpertsProvider = ({ children }) => {
  const [backstageAllSpeakers, setBackstageAllSpeakers] = useState({
    allSpeakers: [],
    dataToBeRender: [],
    page: 0,
  });

  const backstageRef=useRef(backstageAllSpeakers)

  useEffect(()=>{
    backstageRef.current=backstageAllSpeakers
  },[backstageAllSpeakers])

  const fetchSpeakersData = async () => {
    let res = await SpeakerManager._get.getSpeakersFromBackstage();
    return res.data; // Assuming res.data is an array of speaker objects
  };

  const paginatedSpeakerData = (array, pageNumber) => {
    const startIndex = pageNumber * OUR_EXPERT_ITEM_PER_PAGE;
    const endIndex = startIndex + OUR_EXPERT_ITEM_PER_PAGE;
    return array.slice(startIndex, endIndex);
  };

  const getSpeakersData = async () => {
    if (backstageRef.current.page === 0) {
      let res = await fetchSpeakersData();
      let renderSpeakers = paginatedSpeakerData(res, backstageRef.current.page);
      setBackstageAllSpeakers((prev) => ({
        ...backstageRef.current,
        allSpeakers: res,
        dataToBeRender: renderSpeakers,
        page: 1,
      }));
    } else {
     
      let renderSpeakers = paginatedSpeakerData(backstageRef.current.allSpeakers, backstageRef.current.page);
      setBackstageAllSpeakers((prev) => ({
        ...backstageRef.current,
        dataToBeRender: [...backstageRef.current.dataToBeRender, ...renderSpeakers],
        page: backstageRef.current.page + 1,
      }));
    }
  };

  return (
    <OurExpertsContext.Provider value={{ getSpeakersData, backstageAllSpeakers }}>
      {children}
    </OurExpertsContext.Provider>
  );
};

export default OurExpertsProvider;
