//Imports
import { USER_COLLECTION } from '../../AppConstants/CollectionConstants';

// Firebase
import firebase, { firestore } from '../../firebase/firebase';

const updateUser = async (
	UserId,
	{ fullName, phoneNumber, email, profession, speciality, country, state, city, pincode, MCI_Number }
) => {
	try {
		const docRef = firestore.collection(USER_COLLECTION).doc(UserId);

		await firestore.runTransaction(async (transcation) => {
			const doc = await transcation.get(docRef);
			if (!doc.exists) {
				const err = {
					code: 'NotValidId',
					message: 'No User Exists'
				};
				throw err;
			}

			const _data = doc.data();
			// Setup User Document
			let user = {
				fullName: fullName ? fullName : _data.fullName,
				phoneNumber: phoneNumber ? phoneNumber : _data.phoneNumber,
				email: email ? email : _data.email,
				profession: profession ? profession : _data.profession,
				speciality: speciality ? speciality : _data.speciality,
				country: country ? country : _data.country,
				state: state ? state : _data.state,
				city: city ? city : _data.city,
				pincode: pincode ? pincode : _data.pincode,
				MCI_Number: MCI_Number ? MCI_Number : _data.MCI_Number,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			};
			transcation.update(docRef, user);
		});

		return UserId;
	} catch (error) {
		console.log('Error:: User:: ', error);
		throw error;
	}
};

const put = {
	updateUser
};

export default put;
