import _get from './get';
// import _post from './post';
import _put from './put';

const UserMetadataManager = {
	_get,
	// _post,
	_put
};

export default UserMetadataManager;
