const uniqid = require("uniqid");

function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}
export function isiOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export const isLandscape = () => {
  if (window.matchMedia("(orientation: portrait)").matches) {
    // you're in PORTRAIT mode
    return false;
  }

  if (window.matchMedia("(orientation: landscape)").matches) {
    // you're in LANDSCAPE mode
    return true;
  }
};
// const deviceType = () => {
//   let deviceType = "desktop";

//   if (navigator.userAgent.match(/iPad/i)) {
//     //code for iPad here
//     deviceType = "iPad";
//   } else if (navigator.userAgent.match(/iPhone/i)) {
//     //code for iPhone here
//     deviceType = "iPhone";
//   } else if (navigator.userAgent.match(/Android/i)) {
//     //code for Android here
//     deviceType = "Android";
//   } else if (navigator.userAgent.match(/BlackBerry/i)) {
//     //code for BlackBerry here
//     deviceType = "BlackBerry";
//   } else if (navigator.userAgent.match(/webOS/i)) {
//     //code for webOS here
//     deviceType = "webOS";
//   }
//   return deviceType;
// };
const getOS = () => {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac_OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }
  return os;
};
export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
const isMobileDevice = isMobile() || isiOS();
let getSystemID = () => {
  let _id = getCookie("systemId");
  if (_id) {
    return _id;
  } else {
    let id = `${getOS()}_${uniqid()}`;
    document.cookie = `systemId=${id}`;
    return id;
  }
};

export { isMobile, isMobileDevice, getOS, getSystemID };
