//Imports
import { SPEAKERS_COLLECTION } from '../../AppConstants/CollectionConstants';
import { ContentType } from '../../AppConstants/TypeConstants';
// Firebase
import firebase, { firestore } from '../../firebase/firebase';

const updateSpeaker = async (
	speakerId,
	{ name, photoUrl, therapy, designation, degree, profileLine, featuredIndex }
) => {
	try {
		const docRef = firestore.collection(SPEAKERS_COLLECTION).doc(speakerId);

		await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No speakerId found'
				};
				throw err;
			}

			const oldData = doc.data();

			const speaker = {
				name: name ? name : oldData.name,
				photoUrl: photoUrl ? photoUrl : oldData.photoUrl,
				therapy: therapy ? therapy : oldData.therapy,
				designation: designation ? designation : oldData.designation,
				degree: degree ? degree : oldData.degree,
				profileLine: profileLine ? profileLine : oldData.profileLine,
				featuredIndex: featuredIndex && featuredIndex !== null ? featuredIndex : oldData.featuredIndex,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp()
			};

			transcation.update(docRef, speaker);
		});
		return speakerId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = {
	updateSpeaker
};

export default put;
