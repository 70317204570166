import _get from './get';
import _post from './post';
import _delete from './delete';

const SpeakerFollowManager = {
	_get,
	_post,
	_delete
};

export default SpeakerFollowManager;
