//Imports
import {
	SERIES_COLLECTION,
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	MEDIA_METADATA_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { SourceType, VideoType, ContentType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const updateSeries = async (
	seriesId,
	{
		title,
		description,
		type,
		tags,
		speakers,
		thumbnailUrl,
		featuredIndex,
		detailsId,
		therapy,
		associationType,
		associationId
	}
) => {
	if (!seriesId) throw new Error('seriesId undefined');
	try {
		const docRef = firestore.collection(SERIES_COLLECTION).doc(seriesId);
		await firestore.runTransaction(async (transaction) => {
			let doc = await transaction.get(docRef);
			let oldData = doc.data();

			let metaDataRef = firestore.collection(MEDIA_METADATA_COLLECTION).doc(oldData.metaDataId);
			let metaDoc = await transaction.get(metaDataRef);
			let oldMetaData = metaDoc.data();

			tags = tags ? tags.map((t) => t.toLowerCase()) : [];
			speakers = speakers ? speakers : [];
			therapy = therapy ? therapy.map((t) => t.toLowerCase()) : [];

			// Updating series
			transaction.update(docRef, {
				title: title ? title : oldData.title,
				description: description ? description : oldData.description,
				speakers: speakers ? speakers : oldData.speakers,
				tags: tags ? [ ...tags, ...therapy ] : oldData.tags,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
				detailsId: detailsId ? detailsId : oldData.detailsId,
				featuredIndex: featuredIndex !== undefined ? featuredIndex : oldData.featuredIndex,
				thumbnailUrl: thumbnailUrl ? thumbnailUrl : oldData.thumbnailUrl,
				type: type ? type : oldData.type
			});

			// Updating series metadata
			transaction.update(metaDataRef, {
				title: title ? title : oldData.title,
				description: description ? description : oldData.description,
				updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),

				therapy: therapy ? therapy : oldMetaData.therapy,
				tags: tags ? [ ...tags, ...therapy ] : oldMetaData.tags,

				associationType: associationType ? associationType : oldMetaData.associationType,
				associationId: associationId ? associationId : oldMetaData.associationId
			});
		});
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const put = {
	updateSeries
};

export default put;
