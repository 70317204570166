import { POLLS_COLLECTION } from '../../../AppConstants/CollectionConstants';

import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const createPoll = async ({ index, options, question, webinarId, agendaId }) => {
	try {
		const pollId = uniqid('poll-');

		let poll = {
			index,
			id: pollId,
			options,
			question,
			webinarId,
			agendaId,
			status: 1,
			totalResponse: 0,
			timestamp: firebase.firestore.FieldValue.serverTimestamp()
		};

		await firestore.runTransaction(async (transaction) => {
			transaction.set(firestore.collection(POLLS_COLLECTION).doc(pollId), poll);
		});

		return pollId;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = {
	createPoll
};

export default post;
