export const WEBINAR_CHAT_DB = {
  WEBINAR_CHAT: "/webinarChat",
  ROOM: "/chat",
  ROOM_MESSAGE: "/roomMessages",
  USER_TYPING_STATUS: "/roomTypingStatus",
  USER_STATUS: "/user_status",
  ROOM_META_DATA: "/roomMetaData",
  USER_NOTIFICATION: "/userNotification",
  ROOM_TYPING_INDICATOR: "/roomTypingIndicator",
};
