//Imports
import { USER_METADATA_COLLECTION } from '../../../AppConstants/CollectionConstants';
// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

let userMetaDataListenerRef = null;

const getUserMetadata = async (userMetadataId) => {
	try {
		const docRef = firestore.collection(USER_METADATA_COLLECTION).doc(userMetadataId);
		let doc = await docRef.get();
		if (!doc.exists) {
			let err = {
				code: 'NotValidId',
				message: 'No Metadata found'
			};
			throw err;
		}

		return doc.data();
		// const _data = await firestore.runTransaction(async (transcation) => {
		// 	let doc = await transcation.get(docRef);
		// 	if (!doc.exists) {
		// 		let err = {
		// 			code: 'NotValidId',
		// 			message: 'No Metadata found'
		// 		};
		// 		throw err;
		// 	}

		// 	return doc.data();
		// });
		// return _data;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const attachUserMetadata = (userid, cb) => {
	const ref = firestore.collection(USER_METADATA_COLLECTION).doc(userid);
	userMetaDataListenerRef = ref.onSnapshot(
		(doc) => {
			if (doc.exists) {
				if (cb) {
					cb(doc.data());
				}
			} else {
				if (cb) {
					cb({});
				}
			}
		},
		(err) => {
			console.error(err);
			if (cb) {
				cb({});
			}
		}
	);
};

const removeMetadataListener = () => {
	if (userMetaDataListenerRef) {
		userMetaDataListenerRef();
	}
};

const get = {
	getUserMetadata,
	attachUserMetadata,
	removeMetadataListener
};

export default get;
