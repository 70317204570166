//Imports
import {
	VIDEO_COLLECTION,
	LIKES_COLLECTION,
	SPEAKERS_COLLECTION,
	TRANSCIPT_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { SourceType, VideoType, LikeType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';
const uniqid = require('uniqid');

const createTranscript = async (targetId, content, type = 'video') => {
	try {
		const id = uniqid('transcript-');
		const docRef = firestore.collection(VIDEO_COLLECTION).doc(targetId);
		const transcriptRef = firestore.collection(TRANSCIPT_COLLECTION).doc(id);

		const transcript = await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No EventId Found'
				};
				throw err;
			}

			let _data = doc.data();
			if (_data.transcriptId) {
				let err = {
					code: 'Already Exists',
					message: 'Transcript for this video already exists'
				};
				throw err;
			}

			//Create transript
			transcation.set(transcriptRef, {
				id,
				targetId,
				type,
				timestamp: firebase.firestore.FieldValue.serverTimestamp(),
				content
			});
			//Update video doc
			transcation.update(docRef, {
				transcriptId: id
			});

			return id;
		});
		return transcript;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const post = { createTranscript };

export default post;
