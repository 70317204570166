//Imports
import {
	VIDEO_COLLECTION,
	RATINGS_COLLECTION,
	WEBINAR_COLLECTION,
	AGENDA_COLLECTION,
	SERIES_COLLECTION,
	SEASON_COLLECTION
} from '../../../AppConstants/CollectionConstants';
import { ContentType } from '../../../AppConstants/TypeConstants';

// Firebase
import firebase, { firestore } from '../../../firebase/firebase';

const deleteRating = async (targetId, userId, targetType = ContentType.VIDEO) => {
	try {
		const COLLECTION = {
			[ContentType.VIDEO]: VIDEO_COLLECTION,
			[ContentType.WEBINAR]: WEBINAR_COLLECTION,
			[ContentType.AGENDA]: AGENDA_COLLECTION,
			[ContentType.SERIES]: SERIES_COLLECTION,
			[ContentType.SEASON]: SEASON_COLLECTION
		};

		let targetCollection = COLLECTION[targetType];
		const docRef = firestore.collection(targetCollection).doc(targetId);
		const RatingRef = firestore.collection(RATINGS_COLLECTION).doc(`${userId}+${targetId}`);

		const _rating = await firestore.runTransaction(async (transcation) => {
			let doc = await transcation.get(docRef);
			if (!doc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No VideoId Found'
				};
				throw err;
			}

			if (!doc.data()['ratingsgiven']) {
				let err = {
					code: 'NotValid',
					message: 'No ratings given previously to this asset'
				};

				throw err;
			}

			let ratingDoc = await transcation.get(RatingRef);
			if (!ratingDoc.exists) {
				let err = {
					code: 'NotValidId',
					message: 'No RatingId Found'
				};
				throw err;
			}

			// Fetch rating data
			let videoData = doc.data();
			let ratingData = ratingDoc.data();
			let userRating = ratingData['rating'];

			let currentVideoAvgRating = videoData['rating'];
			let currentVideoRatingsGiven = videoData['ratingsgiven'];
			let totalVideoRating = currentVideoAvgRating * currentVideoRatingsGiven;

			let updatedVideoRatingsGiven = currentVideoAvgRating - 1;
			let updatedTotalVideoRating = totalVideoRating - userRating;
			let updatedVideoAvgRating = updatedTotalVideoRating / updatedVideoRatingsGiven;

			transcation.update(docRef, {
				rating: updatedVideoAvgRating.toFixed(2),
				ratingsgiven: firebase.firestore.FieldValue.increment(-1)
			});
			transcation.delete(RatingRef);

			return updatedVideoAvgRating.toFixed(2);
		});
		return _rating;
	} catch (error) {
		console.log('Error :: ', error);
		throw error;
	}
};

const _delete = {
	deleteRating
};

export default _delete;
