import { database, cloudFunction_as } from "../../firebase/firebase";

const getEmployeeData = async ({ number }) => {
  try {
    let obj = {
      employeeData: null,
      teamsData: null,
      overallAnalytics: null,
      position: "",
      doctorsData: {
        data: null,
        queryMetadata: null,
        pages: {
          0: undefined,
        },
        currentPage: 0,
      },
    };
    let employeeDoc = await database.ref("FieldAgentsDev").child(number).get();

    if (!employeeDoc.exists()) {
      throw "Employee does not exists";
    }

    obj["employeeData"] = employeeDoc.val();
    let employeeCode = obj["employeeData"]["employeeCode"];

    const { key, value } = await getPosition(employeeCode);
    obj["position"] = value;

    // todo check and call after 24 hours
    let employeeDataRef = cloudFunction_as.httpsCallable("fetchEmployeeData");

    let response = await employeeDataRef({
      employeeId: employeeCode,
      position: obj["position"],
      isDev: true,
    });

    obj["overallAnalytics"] = await getOverallAnalytics(
      employeeCode,
      obj["position"]
    );

    obj["teamsData"] = await getTeamsData({
      orderBy: obj["position"],
      limit: 5,
      equalTo: parseInt(key),
      number: number,
    });

    let _doctorsData = await getDoctorsData({
      position: obj["position"],
      employeeId: parseInt(key),
    });
    obj["doctorsData"]["queryMetadata"] = _doctorsData.queryMetadata;
    obj["doctorsData"]["data"] = _doctorsData.rows;
    obj["doctorsData"]["pages"] = {
      0: undefined,
      1: _doctorsData.queryMetadata.pageToken,
    };
    obj["doctorsData"]["currentPage"] = 1;

    return obj;
  } catch (error) {
    throw new Error(error);
  }
};

const getOverallAnalytics = async (
  employeeId,
  position,
  memberId = null,
  memberPosition = null
) => {
  try {
    let res = await cloudFunction_as.httpsCallable("fetchEmployeeAggregated")({
      employeeId: employeeId,
      position: position,
      memberPosition: memberPosition,
      memberId: memberId,
      isDev: true,
    });
    let parsedData = JSON.parse(res.data);
    if (parsedData && parsedData.rows) {
      return parsedData.rows[0];
    }
  } catch (error) {
    console.error(error);
  }
};

const getTeamsData = async ({ orderBy, limit, equalTo, number }) => {
  try {
    let teamsDataDoc = null;
    if (!limit) {
      teamsDataDoc = await database
        .ref("FieldAgentsDev")
        .orderByChild(orderBy)
        .equalTo(equalTo)
        .get();
    } else {
      teamsDataDoc = await database
        .ref("FieldAgentsDev")
        .orderByChild(orderBy)
        .limitToFirst(limit)
        .equalTo(equalTo)
        .get();
    }

    if (!teamsDataDoc.exists()) {
      // throw "No team members";
      console.error("No team members");
      return;
    }
    let obj = teamsDataDoc.val();

    if (obj[number]) {
      delete obj[number];
    }

    return obj;
  } catch (error) {
    console.error(error);
  }
};

const getDoctorsData = async ({
  employeeId,
  position,
  memberId = null,
  memberPosition = null,
  search = null,
  pageToken = undefined,
}) => {
  try {
    let res = await cloudFunction_as.httpsCallable("searchDoctor")({
      employeeId: employeeId,
      position: position,
      memberPosition: memberPosition,
      memberId: memberId,
      search: search,
      pageToken: pageToken,
      isDev: true,
    });
    let parsedData = JSON.parse(res.data);
    if (parsedData && parsedData.rows) {
      return parsedData;
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getDoctorTableData = async (data) => {
  try {
    //  for doctor report under the particular user
    let res = await cloudFunction_as.httpsCallable("fetchRegistrations")({
      ...data,
      isDev: true,
    });
    let parsedData = JSON.parse(res.data);

    if (parsedData && parsedData.rows) {
      return parsedData;
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getTeamTableData = async (data) => {
  try {
    //  for doctor report under the particular user
    let res = await cloudFunction_as.httpsCallable("fetchTeamwiseAnalytics")({
      ...data,
      isDev: true,
    });
    let parsedData = JSON.parse(res.data);
    if (parsedData && parsedData.rows) {
      let filteredRows = parsedData.rows?.filter(
        (t) => t.employeeCode != data.employeeId
      );
      if (data.memberId) {
        filteredRows = filteredRows.filter(
          (t) => t.employeeCode != data.memberId
        );
      }
      return {
        ...parsedData,
        rows: filteredRows,
      };
    }
  } catch (error) {
    throw new Error(error);
  }
};

const getPosition = async (employeeCode) => {
  try {
    let _positionDoc = await database
      .ref("FieldAgentsPositionDev")
      .child(employeeCode)
      .get();

    if (!_positionDoc.exists()) {
      // throw "Employee position not exists";
      console.error("No position exists");
      return;
    }

    return { key: _positionDoc.key, value: _positionDoc.val() };
  } catch (error) {
    console.error(error);
  }
};

const get = {
  getEmployeeData,
  getTeamsData,
  getDoctorTableData,
  getTeamTableData,
  getPosition,
  getOverallAnalytics,
  getDoctorsData,
};

export default get;
