//Imports
import { USER_NOTES } from "../../../AppConstants/CollectionConstants";

// Firebase
import firebase, { firestore } from "../../../firebase/firebase";

const createNote = async ({
  targetId,
  userId,
  noteContent,
  startTime,
  endTime,
}) => {
  try {
    if (!userId && !targetId) {
      throw new Error("Please provide a userId and targetId");
    }

    const noteId = `${userId}_${targetId}`;
    const docRef = firestore.collection(USER_NOTES).doc(noteId);

    await firestore.runTransaction(async (transaction) => {
      let doc = await transaction.get(docRef);
      if (!doc.exists) {
        transaction.set(docRef, {
          id: noteId,
          targetId,
          userId,
          content: [
            {
              startTime: startTime,
              endTime: endTime,
              creationTimestamp: firebase.firestore.Timestamp.now(),
              updatedTimestamp: firebase.firestore.Timestamp.now(),
              note: noteContent,
              id: 0,
            },
          ],
        });
      } else {
        let _data = doc.data();
        let contentLen = _data.content.length;
        transaction.update(docRef, {
          content: firebase.firestore.FieldValue.arrayUnion({
            startTime: startTime,
            endTime: endTime,
            creationTimestamp: firebase.firestore.Timestamp.now(),
            updatedTimestamp: firebase.firestore.Timestamp.now(),
            note: noteContent,
            id: contentLen,
          }),
        });
      }
    });

    return noteId;
  } catch (error) {
    console.log("Error:: Notes:: ", error);
    throw error;
  }
};

const post = {
  createNote,
};

export default post;
