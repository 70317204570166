import { createContext, useContext, useReducer } from 'react'
import SpeakerManager from '../../Managers/Speakers';

export const SpeakerContext = createContext(null)

/*
-> get speaker data using there id
-> save speaker data if fetching from firebase and return to the application
-> return it from state if that is avalable
*/

const INITAL_STATE = {}
const ACTION = {
    UDPATE_SPEAKER_DATA: 0,
}

const reducer = (state, action) => {
    let { type, payload } = action
    switch (type) {
        case ACTION.UDPATE_SPEAKER_DATA:
            //payload - object with speakerId as key and it's data
            return { ...state, ...payload }
        default:
            throw new Error();
    }
}


export default function SpeakerContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, INITAL_STATE)

    const getSpeaker = (speakerId) => {
        return new Promise(async (res, rej) => {
            try {
                let isSpeakerDataAvailable = state.hasOwnProperty(speakerId)
                if (isSpeakerDataAvailable) {
                    res(state[speakerId])
                } else {
                    let speakerData = await SpeakerManager._get.getSpeakerById(speakerId)
                    dispatch({ type: ACTION.UDPATE_SPEAKER_DATA, payload: { [speakerId]: speakerData } })
                    res(speakerData)
                }
            } catch (error) {
                console.error(error)
                rej(error)
            }
        })
    }

    const getMultipleSpeakersData = (speakerId_array = []) => {
        return new Promise(async (res, rej) => {
            try {
                const data = await Promise.all(speakerId_array.map(speakerId => getSpeaker(speakerId)))
                res(data)
            } catch (error) {
                console.error(error)
                rej(error)
            }
        })
    }

    return (
        <>
            <SpeakerContext.Provider value={{ getSpeaker, getMultipleSpeakersData }}>
                {children}
            </SpeakerContext.Provider>
        </>
    )
}

export const useSpeakerContext = () => useContext(SpeakerContext);
