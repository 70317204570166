import { CloudFunctionName } from "../../AppConstants/CloudFunctionName";
import { BACKSTAGE_COLLECTION } from "../../AppConstants/CollectionConstants";
import {
  ALLTAGS,
  DOCFLIX_DOC_CONSTANT,
  SEARCH_PAGE,
} from "../../AppConstants/FirestoreDocumentConstant";
import { cloudFunction_as, firestore } from "../../firebase/firebase";

const getTagsData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = firestore.collection(BACKSTAGE_COLLECTION).doc(ALLTAGS);
      let doc = await docRef.get();
      if (doc.exists) {
        resolve(doc.data().data);
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getSpeakerPageData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = firestore
        .collection(BACKSTAGE_COLLECTION)
        .doc(SEARCH_PAGE);
      let doc = await docRef.get();
      if (doc.exists) {
        resolve(doc.data());
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getDocflixConstant = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const docRef = firestore
        .collection(BACKSTAGE_COLLECTION)
        .doc(DOCFLIX_DOC_CONSTANT);
      let doc = await docRef.get();
      if (doc.exists) {
        resolve(doc.data());
      } else {
        resolve(null);
      }
    } catch (error) {
      reject(error);
    }
  });
};

// const getDocflixConstant = () => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const functionRef = cloudFunction_as.httpsCallable(
//         CloudFunctionName.GET_GLOBAL_ATTRIBUTES
//       );

//       let res = await functionRef();
//       if (res.data && res.data.status === "Successfull") {
//         resolve(res.data.data);
//       } else {
//         resolve(null);
//       }
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

export const BackstageManager = {
  getTagsData,
  getSpeakerPageData,
  getDocflixConstant,
};
